<template>
  <section class="page-interaction">
    <b-overlay :show="loading" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-row v-for="(call, i) in videoCalls" :key="i">
        <call-card :call="call" call-type="video" @setLoading="setLoading($event)"/>
      </b-row>
      <b-row v-if="!loading && videoCalls.length < 1">
        <discover-no-user>
          <h3 class="color-black">
            {{ $t('No one in the call log yet') }}
          </h3>
        </discover-no-user>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BAlert, BAvatar, BBadge, BButton, BCard, BCarousel, BCarouselSlide, BCol, BDropdown, BDropdownItem, BFormInput, BImg,
  BLink, BMedia, BOverlay, BPagination, BRow, BTable, BTabs, BTab,
} from 'bootstrap-vue'
import { Carousel3d, Slide } from 'vue-carousel-3d'
import useJwt from '@/auth/jwt/useJwt'
import { showNotify, throwDefaultError } from '@/helpers/helpers'
import StatusIndicator from '@/components/StatusIndicator'
import DiscoverNoUser from '@/components/DiscoverNoUser'
import CallCard from '@/components/CallCard'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'

export default {
  name: 'CallsIndex',
  components: {
    BAlert,
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCarousel,
    BCarouselSlide,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BImg,
    BLink,
    BMedia,
    BOverlay,
    BPagination,
    BRow,
    BTable,
    BTabs,
    BTab,

    CallCard,
    DiscoverNoUser,
    StatusIndicator,

    Carousel3d,
    Slide,

    vSelect,
  },
  data() {
    return {
      calls: [],
      limit: 300,
      loading: false,
      failedCalls: [],
      iconPhone: require('@/assets/images/icons/phone-green.svg'),
      successCalls: [],
    }
  },
  computed: {
    userId() {
      return this.$store.getters['auth/userId']
    },
    videoCalls() {
      return this.calls.filter(call => call.media === 'video')
    }
  },
  created() {
    this.getCalls()
  },
  methods: {
    avatarText(text) {
      return avatarText(text)
    },
    call(id) {
      this.loading = true
      if (!this.store.getters['auth/userPhoneNumber']) {
        this.$store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
        this.$store.dispatch('app/setModalAddPhoneNumber', true)
        this.$store.dispatch('app/setVerificationType', 'phone')
      } else {
        useJwt.callUser(id)
            .then(response => {
              showNotify(this.$toast, this.$t('Calling successful'), 'success')
            })
            .catch(error => {
              const { response } = error
              throwDefaultError(this.$toast, error)
              if (response && response.data?.header?.error?.errorCode == 215) {
                this.$store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
                this.$store.dispatch('app/setModalAddPhoneNumber', true)
                this.$store.dispatch('app/setVerificationType', 'phone')
              }
            })
            .finally(() => { this.loading = false })
      }
    },
    getCalls() {
      this.loading = true
      useJwt.getCallHistory(0, this.limit)
          .then(response => {
            try {
              this.$nextTick(() => {
                // const incomingCalls = response.data.body.phoneCallsAnswered.map(incomingCall => {
                //   return {
                //     type: 'incoming',
                //     user: {
                //       userId: incomingCall.fromUserId,
                //       userName: incomingCall.fromUserName,
                //       profileImage: incomingCall.fromProfileImage,
                //     },
                //     time: incomingCall.callStartedAt,
                //     duration: incomingCall.callDurationInSeconds,
                //     callStatus: incomingCall.callStatus,
                //     callType: incomingCall.callType,
                //     userStatus: incomingCall.toUserOnlineStatus,
                //   }
                // })
                // const outgoingCalls = response.data.body.phoneCallsMade.map(outgoingCall => {
                //   return {
                //     type: 'outgoing',
                //     user: {
                //       userId: outgoingCall.toUserId,
                //       userName: outgoingCall.toUserName,
                //       profileImage: outgoingCall.toProfileImage,
                //     },
                //     time: outgoingCall.callStartedAt,
                //     duration: outgoingCall.callDurationInSeconds,
                //     callStatus: outgoingCall.callStatus,
                //     callType: outgoingCall.callType,
                //     userStatus: outgoingCall.toUserOnlineStatus,
                //   }
                // })
                //
                // this.calls = [...incomingCalls, ...outgoingCalls]

                this.successCalls = response.data.body.successCalls.map(call => {
                  return {
                    ...call,
                    type: call.fromUuid === this.userId ? 'outgoing' : 'incoming',
                    user: {
                      userId: call.fromUuid === this.userId ? call.toUuid : call.fromUuid,
                      userName: call.fromUuid === this.userId ? call.toUserName : call.fromUserName,
                      profileImage: call.fromUuid === this.userId ? call.toProfileImage : call.fromProfileImage,
                    },
                    time: call.callStartedAt,
                    duration: call.callDurationInSeconds,
                    callStatus: call.callStatus,
                    callType: call.callType,
                    userStatus: call.fromUuid ? call.toOnlineStatus : call.fromOnlineStatus,
                  }
                })

                this.failedCalls = response.data.body.failedCalls.map(call => {
                  return {
                    ...call,
                    type: call.fromUuid === this.userId ? 'outgoing' : 'incoming',
                    user: {
                      userId: call.fromUuid === this.userId ? call.toUuid : call.fromUuid,
                      userName: call.fromUuid === this.userId ? call.toUserName : call.fromUserName,
                      profileImage: call.fromUuid === this.userId ? call.toProfileImage : call.fromProfileImage,
                    },
                    time: call.callStartedAt,
                    duration: call.callDurationInSeconds,
                    callStatus: call.callStatus,
                    callType: call.callType,
                    userStatus: call.fromUuid ? call.toOnlineStatus : call.fromOnlineStatus,
                  }
                })

                this.calls = [...this.successCalls, ...this.failedCalls]
              })
            } catch (e) {
              throwDefaultError(this.$toast)
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    resolveUserTypeVariant(type) {
      if (type === 'incoming') return 'warning'
      return 'primary'
    },
    resolveUserStatusVariant(type) {
      if (type === 'completed') return 'secondary'
      return 'success'
    },
    setLoading($event) {
      this.loading = $event
    },
  }
}
</script>
